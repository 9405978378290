import { axiosBase } from "../constants/axiosBase"

type ChangeOwnInfoType = {
	isOwn: boolean
	sellerId: number
}

export const changeOwnInfo = async (data: ChangeOwnInfoType) => {
	return await axiosBase.post("/seller/is-own/", data)
}
