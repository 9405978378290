import { ChangeEvent, useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { GlobalContext } from "../../context/GlobalContext"
import { DailyReport } from "../../classes"
import { Loader } from "../../component/Loader"
import xlsx from "json-as-xlsx"

export const ReportPage = () => {
	const auth = useContext(GlobalContext)
	const [load, setLoad] = useState(true)
	const [report, setReport] = useState(new DailyReport())

	const [formDate, setFormDate] = useState({
		startDate: "",
		endDate: "",
	})

	const LoadExcel = (startDate: string, endDate: string) => {
		report
			.LoadExcel(startDate, endDate, auth.user.userToken)
			.then(function (response) {
				const data = [
					{
						sheet: "Adults",
						columns: [
							{ label: "Дата создания", value: "date" },
							{ label: "Количество регистраций блогеров", value: "blogerCreate" },
							{ label: "Количество регистраций селлеров", value: "sellerCreate" },
							{ label: "Количество проектов создано", value: "integrationCount" },
							{ label: "Кол-во исх заявок от блогеров", value: "applicationsCount" },
							{ label: "Количество откликов на 1 заявку селлера в среднем", value: "intApplicMidleCount" },
							{ label: "Количество интеграций завершено", value: "inComplete" },
							{ label: "Количество интеграций отменено", value: "inClose" },
							{ label: "Количество интеграций в работе", value: "inWork" },
							{ label: "Статус интеграции (Заявка принята)", value: "in_process" },
							{ label: "Статус интеграции (ТЗ принято)", value: "tz_conf" },
							{ label: "Статус интеграции (Ожидание реквизитов)", value: "w8paydata" },
							{ label: "Статус интеграции (Ожидание оплаты)", value: "payment_w8" },
							{ label: "Статус интеграции (Блогер подтвердил оплату)", value: "payment_completed" },
							{ label: "Статус интеграции (Товар заказан)", value: "product_ordered" },
							{ label: "Статус интеграции (Товар получен)", value: "product_received" },
							{ label: "Статус интеграции (Пост размещен)", value: "post_posted" },
							{ label: "Статус интеграции (Блогер подтвердил выполнение)", value: "bloger_done" },
							{ label: "Статус интеграции (Ожидание отзыва от блогера)", value: "w8_bloger_feedback" },
						],
						content: response,
					},
				]
				let settings = {
					fileName: "Интеграции",
				}
				xlsx(data, settings)
			})
			.catch(function (error) {
				alert("error")
			})
	}

	useEffect(() => {
		const LoadData = () => {
			setLoad(true)
			report
				.LoadData(auth.user.userToken)
				.then(function (response) {
					setLoad(false)
				})
				.catch(function (error) {
					alert("error")
					setLoad(false)
				})
		}

		LoadData()
	}, [])

	const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setFormDate({ ...formDate, [event.target.name]: event.target.value })
	}

	if (load) return <Loader />

	return (
		<div className="content">
			<div className="row">
				<div className="col-md-8">
					<div className="block-wrapper">
						<form
							className="row date-filter"
							onSubmit={(e) => {
								e.preventDefault()
								LoadExcel(formDate.startDate, formDate.endDate)
							}}
						>
							<div className="col-md-4">
								<label>
									Дата от:
									<input
										required
										type="date"
										name="startDate"
										value={formDate.startDate}
										onChange={changeHandler}
									/>
								</label>
							</div>
							<div className="col-md-4">
								<label>
									Дата до:
									<input
										required
										type="date"
										name="endDate"
										value={formDate.endDate}
										onChange={changeHandler}
									/>
								</label>
							</div>

							<div className="col-md-4">
								<button>Скачать excel</button>
							</div>
						</form>
					</div>
				</div>

				<div className="col-12">
					<div className="row">
						{report.list.map((item: any, index: number) => {
							return (
								<div className="col-md-3">
									<div className="report-item">
										<div
											onClick={() => {
												LoadExcel(item.date, item.date)
											}}
										>
											<svg
												width="20px"
												height="20px"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z"
													fill="#bfbfbf"
												/>
											</svg>
											{item.date}
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}
