import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { GlobalContext } from "../../context/GlobalContext"
import { Pay } from "../../classes"
import { Loader } from "../../component/Loader"
import { Pagination } from "../../component/Pagination"
import xlsx from "json-as-xlsx"

export const PaymentsListPage = () => {
	const auth = useContext(GlobalContext)
	const [pay] = useState(new Pay())
	const [load, setLoad] = useState(true)
	const [search, setSearch] = useState("")

	const LoadData = (page = 1) => {
		setLoad(true)
		pay.LoadData(auth.user.userToken, search, page)
			.then(function (response) {
				setLoad(false)
			})
			.catch(function (error) {
				alert("error")
			})
	}

	const LoadExcel = () => {
		pay.LoadData(auth.user.userToken, search).then(function (response) {
			const Harray = pay.list.map((item) => ({
				id: item.pk,
				user: item.user.email,
				title: item.title,
				date: item.date,
				status: item.status,
			}))
			const data = [
				{
					sheet: "Adults",
					columns: [
						{ label: "id", value: "id" },
						{ label: "Пользователь", value: "user" },
						{ label: "Название", value: "title" },
						{ label: "Дата", value: "date" },
						{ label: "Статус", value: "status" },
					],
					content: Harray,
				},
			]

			const settings = {
				fileName: "Платежи",
			}

			xlsx(data, settings)
		})
	}

	useEffect(() => {
		LoadData()
	}, [])

	if (load) return <Loader />

	return (
		<div className="content">
			<div className="row">
				<div className="col-md-4">
					<div className="block-wrapper">
						<form
							className="sform"
							onSubmit={(e) => {
								e.preventDefault()
								LoadData(pay.targetPage)
							}}
						>
							<input
								type="text"
								placeholder="Поиск"
								value={search}
								onChange={(e) => {
									setSearch(e.target.value)
								}}
							/>
							<button>
								<svg
									width="20px"
									height="20px"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z"
										stroke="#000000"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</button>
						</form>
					</div>
				</div>
				<div className="col-md-8">
					<div className="right">
						{auth.user.userRole === "director" ? (
							<button
								className="btn"
								style={{ background: "#207444" }}
								onClick={() => {
									LoadExcel()
								}}
							>
								Выгрузить в excel
							</button>
						) : (
							""
						)}
					</div>
				</div>
				<div className="col-12">
					<div className="block-wrapper table-wrapper">
						<table className="list-table">
							<thead>
								<tr>
									<th>id</th>
									<th>Пользователь</th>
									<th>Название</th>
									<th>Дата</th>
									<th>Статус</th>
								</tr>
							</thead>
							<tbody>
								{pay.list.length
									? pay.list.map((item, index) => {
											return (
												<tr key={index}>
													<td>
														<div>
															<div>{item.pk}</div>
														</div>
													</td>
													<td>
														<div>
															<Link to={`/sellers/${item.user.pk}/`}>{item.user.email}</Link>
														</div>
													</td>
													<td>
														<div>{item.title}</div>
													</td>
													<td>
														<div>{item.date}</div>
													</td>
													<td>
														<div>{item.status}</div>
													</td>
												</tr>
											)
									  })
									: ""}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<Pagination page={pay.targetPage} pageCount={pay.countPage} loadData={LoadData} />
		</div>
	)
}
