export const Loader = () => (
	<h2
		style={{
			textAlign: "center",
			marginTop: 40,
		}}
	>
		Загрузка...
	</h2>
)
