import { axiosBase } from "../constants/axiosBase"

export const isFrozenChange = async (pk: number, isFrozen: boolean) => {
	const response = await axiosBase.get(`/blogger/is-frozen/${pk}/${isFrozen}/`)

	return response.status
}

export const is_self_employed_change = async (pk: number, is_self_employed: boolean) => {
	const response = await axiosBase.patch(`/bloger/${pk}/`, { is_self_employed })

	return response.status
}

export const update_bloger_integration = async (pk: number, integration: number) => {
	if (Number.isNaN(integration)) return

	const response = await axiosBase.patch(`/bloger/${pk}/`, { integration })

	return response.status
}
