import { User } from "../classes"
import { createContext } from "react"

type GlobalContextType = {
	user: User
}

export const GlobalContext = createContext<GlobalContextType>({
	user: new User(),
})
