import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import "./css/bootstrap-grid.css"
import "./css/style.css"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
	<div className="body-wrapper">
		<React.StrictMode>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</React.StrictMode>
	</div>,
)
