import { ChangeEvent, useContext, useEffect, useState } from "react"
import axios from "axios"
import { GlobalContext } from "../../context/GlobalContext"
import { Integrations } from "../../classes"
import { Loader } from "../../component/Loader"
import { Pagination } from "../../component/Pagination"
import { FormatSelector } from "../../component/FormatSelector"
import { IntegrationListItem } from "../../component/IntegrationListItem"
import xlsx from "json-as-xlsx"

export const IntegrationListPage = () => {
	const auth = useContext(GlobalContext)
	const [integrations] = useState(new Integrations())
	const [load, setLoad] = useState(true)
	const [loadForm2, setLoadForm2] = useState(true)
	const [loadForm3, setLoadForm3] = useState(true)
	const [targetItem, setTargetItem] = useState(0)
	const [openSearch, setOpenSearch] = useState(false)
	const [search, setSearch] = useState("")
	// const [loadForm, setLoadForm] = useState(false)
	const [showFormUpdate, setShowFormUpdate] = useState(false)
	const [showFormat, setShowFormat] = useState(false)
	const [fileNameUpdate, setFileNameUpdate] = useState("")

	const [targetSteps, setTargetSteps] = useState<
		{
			name: string
			value: string
		}[]
	>()

	const [formUpdate, setFormUpdate] = useState({
		title: "",
		productTitle: "",
		miniDesc: "",
		desc: "",
		productLink: "",
		productImage: "",
		tz: "",
		soc: "",
		price: 0,
		maxIntegrations: "",
		format1: false,
		format2: false,
		format3: false,
		format4: false,
		format5: false,
	})
	const [formSearch, setFormSearch] = useState({
		startDate: "",
		endDate: "",

		status: "",
		fIntegration: false,

		maxCount_start: 0,
		maxCount_end: 0,

		processCount_start: 0,
		processCount_end: 0,

		doneCount_start: 0,
		doneCount_end: 0,

		applicationCount_start: 0,
		applicationCount_end: 0,
	})
	const [selectArray, setSelectArray] = useState<number[]>([])
	const [selectAll, setSelectAll] = useState(false)
	const [delIntegrationsConf, setDelIntegrationsConf] = useState(false)

	const LoadData = (page = 1) => {
		setLoad(true)
		integrations
			.LoadData(
				auth.user.userToken,
				search,
				page,

				formSearch.startDate,
				formSearch.endDate,

				formSearch.maxCount_start,
				formSearch.maxCount_end,

				formSearch.processCount_start,
				formSearch.processCount_end,

				formSearch.doneCount_start,
				formSearch.doneCount_end,

				formSearch.status,
				formSearch.fIntegration,

				formSearch.applicationCount_start,
				formSearch.applicationCount_end,
			)
			.then(function (response) {
				setLoad(false)
			})
			.catch(function (error) {
				alert("error")
			})
	}

	const changeHandlerSearch = (event: ChangeEvent<HTMLInputElement>) => {
		setFormSearch({ ...formSearch, [event.target.name]: event.target.value })
	}

	const handleSubmitSearch = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setOpenSearch(false)
		LoadData()
	}

	const changeHandlerUpdate = (event: ChangeEvent<HTMLInputElement>) => {
		setFormUpdate({ ...formUpdate, [event.target.name]: event.target.value })
	}

	const handlerImageUpdate = (e: any) => {
		setFormUpdate({ ...formUpdate, productImage: e.target.files[0] })
		setFileNameUpdate(e.target.files[0].name)
	}

	const handleSubmitUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (loadForm3) {
			setLoadForm3(false)

			const formData = new FormData()

			formData.append("title", formUpdate.title)
			formData.append("productTitle", formUpdate.productTitle)
			formData.append("miniDesc", formUpdate.miniDesc)
			formData.append("desc", formUpdate.desc)
			formData.append("productLink", formUpdate.productLink)
			formData.append("productImage", formUpdate.productImage)
			formData.append("tz", formUpdate.tz)
			formData.append("price", `${formUpdate.price}`)
			formData.append("maxIntegrations", formUpdate.maxIntegrations)
			formData.append("format1", `${formUpdate.format1}`)
			formData.append("format2", `${formUpdate.format2}`)
			formData.append("format3", `${formUpdate.format3}`)
			formData.append("format4", `${formUpdate.format4}`)
			formData.append("format5", `${formUpdate.format5}`)

			await axios
				.post(`https://api.blogerito.ru/dashboard/integrations-update/`, formData, {
					params: {
						id: targetItem,
					},
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: "Token " + auth.user.userToken,
					},
				})
				.then((res) => {
					setLoadForm3(true)
					console.log(res.data)
					alert(res.data.msg)
				})
				.catch((error) => {
					setLoadForm3(true)
					console.log(error)
					alert("error")
				})
		}
	}

	const DelIntegrations = (array: number[]) => {
		integrations
			.DelItem(array, auth.user.userToken)
			.then(function (response) {
				alert(`${array.length} интеграций успешно удалено`)
				window.location.reload()
			})
			.catch(function (error) {
				alert("error")
			})
	}

	const LoadExcel = () => {
		integrations
			.LoadExcel(
				auth.user.userToken,
				search,

				formSearch.startDate,
				formSearch.endDate,

				formSearch.maxCount_start,
				formSearch.maxCount_end,

				formSearch.processCount_start,
				formSearch.processCount_end,

				formSearch.doneCount_start,
				formSearch.doneCount_end,

				formSearch.status,
				formSearch.fIntegration,

				formSearch.applicationCount_start,
				formSearch.applicationCount_end,

				selectArray.toString(),
			)
			.then(function (response) {
				const data = [
					{
						sheet: "Adults",
						columns: [
							{ label: "id", value: "id" },
							{ label: "Дата создания", value: "date" },
							{ label: "Название проекта", value: "title" },
							{ label: "Селлер", value: "user" },
							{ label: "Количество интеграций в проект создано селлером", value: "w8_bloger" },
							{ label: "Количество откликов на интеграции создано блогерами", value: "w8_seller" },
							{ label: "Количество интеграций создано", value: "inviteCount" },
							{ label: "Количество интеграций в работе", value: "intWork" },
							{ label: "Количество интеграций завершено", value: "intComplite" },
							{ label: "Количество интеграций отменено", value: "intCancel" },

							{ label: "Статус интеграции (Заявка принята)", value: "in_process" },
							{ label: "Статус интеграции (ТЗ принято)", value: "in_process" },
							{ label: "Статус интеграции (Ожидание реквизитов)", value: "w8-paydata" },
							{ label: "Статус интеграции (Ожидание оплаты)", value: "payment_w8" },
							{ label: "Статус интеграции (Блогер подтвердил оплату)", value: "payment_completed" },
							{ label: "Статус интеграции (Товар заказан)", value: "product_ordered" },
							{ label: "Статус интеграции (Товар получен)", value: "product_received" },
							{ label: "Статус интеграции (Пост размещен)", value: "post_posted" },
							{ label: "Статус интеграции (Блогер подтвердил выполнение)", value: "bloger_done" },
							{ label: "Статус интеграции (Ожидание отзыва от блогера)", value: "w8_bloger_feedback" },
						],
						content: response,
					},
				]
				console.log(data)
				let settings = {
					fileName: "Интеграции",
				}
				xlsx(data, settings)
			})
	}

	const SelectStatus = (name: string, value: string) => {
		let flag = false
		const array = []

		if (targetSteps)
			targetSteps.forEach((item: any, index: number) => {
				if (item.value !== value) array.push(item)
				else flag = true
			})

		if (!flag)
			array.push({
				name: name,
				value: value,
			})
		setTargetSteps(array)
	}

	useEffect(() => {
		LoadData()
	}, [])

	if (load) return <Loader />

	return (
		<>
			<div className="content">
				<div className="row">
					<div className="col-md-4">
						<div className="block-wrapper">
							<div className="filter-wrapper">
								<form
									className="sform"
									onSubmit={(e) => {
										e.preventDefault()
										LoadData(integrations.targetPage)
									}}
								>
									<input
										type="text"
										placeholder="Поиск"
										value={search}
										onChange={(e) => {
											setSearch(e.target.value)
										}}
									/>
									<button>
										<svg
											width="20px"
											height="20px"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z"
												stroke="#000000"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</button>
								</form>
								<div className="open-filter">
									<svg
										width="25px"
										height="25px"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										onClick={() => {
											setOpenSearch(true)
										}}
									>
										<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
											<g id="Filter">
												<rect
													id="Rectangle"
													fillRule="nonzero"
													x="0"
													y="0"
													width="24"
													height="24"
												></rect>
												<line
													x1="4"
													y1="5"
													x2="16"
													y2="5"
													id="Path"
													stroke="#0C0310"
													strokeWidth="1.5"
													strokeLinecap="round"
												></line>
												<line
													x1="4"
													y1="12"
													x2="10"
													y2="12"
													id="Path"
													stroke="#0C0310"
													strokeWidth="1.5"
													strokeLinecap="round"
												></line>
												<line
													x1="14"
													y1="12"
													x2="20"
													y2="12"
													id="Path"
													stroke="#0C0310"
													strokeWidth="1.5"
													strokeLinecap="round"
												></line>
												<line
													x1="8"
													y1="19"
													x2="20"
													y2="19"
													id="Path"
													stroke="#0C0310"
													strokeWidth="1.5"
													strokeLinecap="round"
												></line>
												<circle
													id="Oval"
													stroke="#0C0310"
													strokeWidth="1.5"
													strokeLinecap="round"
													cx="18"
													cy="5"
													r="2"
												></circle>
												<circle
													id="Oval"
													stroke="#0C0310"
													strokeWidth="1.5"
													strokeLinecap="round"
													cx="12"
													cy="12"
													r="2"
												></circle>
												<circle
													id="Oval"
													stroke="#0C0310"
													strokeWidth="1.5"
													strokeLinecap="round"
													cx="6"
													cy="19"
													r="2"
												></circle>
											</g>
										</g>
									</svg>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-8">
						<div className="right">
							{selectArray.length ? (
								auth.user.userRole === "director" ? (
									<button
										className="btn"
										style={{ background: "rgb(255, 70, 70)" }}
										onClick={() => {
											setDelIntegrationsConf(true)
										}}
									>
										Удалить выбранное
									</button>
								) : (
									""
								)
							) : (
								""
							)}
							{auth.user.userRole === "director" ? (
								<button
									className="btn"
									style={{
										background: "#207444",
										cursor: "pointer",
									}}
									onClick={() => {
										LoadExcel()
									}}
								>
									Выгрузить в excel
								</button>
							) : (
								""
							)}
						</div>
					</div>
					<div className="col-12">
						<div className="block-wrapper table-wrapper">
							<div
								style={{
									fontSize: 12,
								}}
							>
								Выбрано: {selectArray.length}
							</div>
							<table className="list-table">
								<thead>
									<tr>
										<th>
											<div>
												<input
													type="checkbox"
													checked={selectAll}
													onClick={() => {
														const Harray: number[] = []
														if (!selectAll) {
															integrations.list.forEach((item) => {
																Harray.push(item.pk)
															})
														}
														setSelectArray(Harray)
														setSelectAll(!selectAll)
													}}
												/>
											</div>
										</th>
										<th>id</th>
										<th>Название проекта</th>
										<th>Количество возможных интеграций</th>
										<th>Селлер</th>
										<th>Дата размещения</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{integrations.list.length
										? integrations.list.map((item, index) => {
												return (
													<IntegrationListItem
														item={item}
														setTargetItem={setTargetItem}
														setShowFormUpdate={setShowFormUpdate}
														setLoadForm2={setLoadForm2}
														setFormUpdate={setFormUpdate}
														setSelectArray={setSelectArray}
														selectArray={selectArray}
														setDelIntegrationsConf={setDelIntegrationsConf}
													/>
												)
										  })
										: ""}
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<Pagination page={integrations.targetPage} pageCount={integrations.countPage} loadData={LoadData} />
			</div>

			{openSearch ? (
				<div className="filter-popup">
					<div>
						<div className="close">
							<svg
								width="30px"
								height="30px"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								style={{
									cursor: "pointer",
								}}
								onClick={() => {
									setOpenSearch(false)
								}}
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
									fill="#0F1729"
								/>
							</svg>
						</div>
						<form onSubmit={handleSubmitSearch}>
							<label>
								Период создания
								<div className="row">
									<div className="col-6">
										<input
											type="date"
											name="startDate"
											value={formSearch.startDate}
											onChange={changeHandlerSearch}
										/>
									</div>
									<div className="col-6">
										<input
											type="date"
											name="endDate"
											value={formSearch.endDate}
											onChange={changeHandlerSearch}
										/>
									</div>
								</div>
							</label>
							<label>
								Статус проекта
								<select
									onChange={(e) => {
										setFormSearch({ ...formSearch, status: e.target.value })
									}}
								>
									<option>Все</option>

									<option selected={formSearch.status === "process" ? true : false} value={"process"}>
										В процессе работы
									</option>
									<option selected={formSearch.status === "done" ? true : false} value={"done"}>
										Завершенные
									</option>
								</select>
							</label>
							<label>
								Кол-во возможных интеграций
								<div className="row">
									<div className="col-6">
										<input
											type="number"
											min={0}
											placeholder="От"
											name="maxCount_start"
											value={formSearch.maxCount_start}
											onChange={changeHandlerSearch}
										/>
									</div>
									<div className="col-6">
										<input
											type="number"
											min={0}
											placeholder="До"
											name="maxCount_end"
											value={formSearch.maxCount_end}
											onChange={changeHandlerSearch}
										/>
									</div>
								</div>
							</label>
							<label>
								Кол-во заявок от блогеров
								<div className="row">
									<div className="col-6">
										<input
											type="number"
											min={0}
											placeholder="От"
											name="applicationCount_start"
											value={formSearch.applicationCount_start}
											onChange={changeHandlerSearch}
										/>
									</div>
									<div className="col-6">
										<input
											type="number"
											min={0}
											placeholder="До"
											name="applicationCount_end"
											value={formSearch.applicationCount_end}
											onChange={changeHandlerSearch}
										/>
									</div>
								</div>
							</label>
							<label>
								Кол-во заявок в процессе выполнения
								<div className="row">
									<div className="col-6">
										<input
											type="number"
											min={0}
											placeholder="От"
											name="processCount_start"
											value={formSearch.processCount_start}
											onChange={changeHandlerSearch}
										/>
									</div>
									<div className="col-6">
										<input
											type="number"
											min={0}
											placeholder="До"
											name="processCount_end"
											value={formSearch.processCount_end}
											onChange={changeHandlerSearch}
										/>
									</div>
								</div>
							</label>
							<label>
								Кол-во интеграций выполнено
								<div className="row">
									<div className="col-6">
										<input
											type="number"
											min={0}
											placeholder="От"
											name="doneCount_start"
											value={formSearch.doneCount_start}
											onChange={changeHandlerSearch}
										/>
									</div>
									<div className="col-6">
										<input
											type="number"
											min={0}
											placeholder="До"
											name="doneCount_end"
											value={formSearch.doneCount_end}
											onChange={changeHandlerSearch}
										/>
									</div>
								</div>
							</label>
							<button>Найти</button>
						</form>
					</div>
				</div>
			) : (
				""
			)}

			{showFormUpdate ? (
				<div className="popup-integration">
					<div className="">
						<div style={{ textAlign: "right" }}>
							<svg
								width="30px"
								height="30px"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								style={{
									cursor: "pointer",
								}}
								onClick={() => {
									setShowFormUpdate(false)
								}}
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
									fill="#0F1729"
								/>
							</svg>
						</div>
						{loadForm2 ? (
							<div style={{ backgroundColor: "white", borderRadius: 10, paddingTop: 20 }}>
								<Loader />
							</div>
						) : (
							<form onSubmit={handleSubmitUpdate}>
								<label>
									Название интеграции
									<input
										type="text"
										maxLength={255}
										required
										style={{ backgroundColor: "white !important", color: "black !important" }}
										name="title"
										value={formUpdate.title}
										onChange={changeHandlerUpdate}
									/>
								</label>
								<label>
									Название товара
									<input
										type="text"
										maxLength={255}
										required
										name="productTitle"
										value={formUpdate.productTitle}
										onChange={changeHandlerUpdate}
									/>
								</label>
								<label>
									Количество возможных интеграций в рамках этого задания
									<input
										required
										type="number"
										min={formUpdate.maxIntegrations}
										step={1}
										value={formUpdate.maxIntegrations}
										name="maxIntegrations"
										onChange={changeHandlerUpdate}
									/>
								</label>
								<label style={{ display: "none" }}>
									Социальная сеть
									<select
										required
										onChange={(e) => {
											setFormUpdate({ ...formUpdate, soc: e.target.value })
										}}
									>
										<option value="" disabled>
											Выбрать
										</option>
										<option value={"instagram"} selected>
											Instagram
										</option>
										<option value={"youtube"}>Youtube</option>
										<option value={"vk"}>Vk</option>
										<option value={"rutube"}>Rutube</option>
										<option value={"telegram"}>Telegram</option>
										<option value={"any"}>Любая</option>
									</select>
								</label>

								<label>
									Формат рекламы
									<FormatSelector
										form={formUpdate}
										setForm={setFormUpdate}
										showFormat={showFormat}
										setShowFormat={setShowFormat}
									/>
								</label>

								<label>
									Короткое описание
									<input
										type="text"
										maxLength={510}
										required
										name="miniDesc"
										value={formUpdate.miniDesc}
										onChange={changeHandlerUpdate}
									/>
								</label>

								<label>
									Цена товара (руб.)
									<input
										type="number"
										min={0}
										step={1}
										required
										name="price"
										value={formUpdate.price}
										onChange={changeHandlerUpdate}
									/>
								</label>

								<label>
									Описание и ваши пожелания
									<textarea
										required
										name="msg"
										value={formUpdate.desc}
										onChange={(e) => setFormUpdate({ ...formUpdate, desc: e.target.value })}
									/>
								</label>

								<label>
									Техническое задание на покупку товара{" "}
									<b style={{ fontSize: 12, fontWeight: 300 }}>
										(будет автоматически выслано блогеру после приема заявки)
									</b>
									<input
										type="text"
										maxLength={510}
										required
										name="tz"
										value={formUpdate.tz}
										onChange={changeHandlerUpdate}
									/>
								</label>

								<label>
									Ссылка на рекламируемую страницу{" "}
									<b style={{ fontSize: 12, fontWeight: 300 }}>(можно оставить пустым)</b>
									<input
										type="text"
										maxLength={510}
										name="productLink"
										value={formUpdate.productLink}
										onChange={changeHandlerUpdate}
									/>
								</label>

								<label style={{ cursor: "pointer" }}>
									<div className="dop-file project">
										Прикрепите фото товара для его изменения
										<input
											type="file"
											name="productImage"
											onChange={handlerImageUpdate}
											accept="image/*"
										/>
									</div>
								</label>

								<button>Обновить интеграцию</button>
							</form>
						)}
					</div>
				</div>
			) : (
				""
			)}

			{delIntegrationsConf ? (
				<div className="popup-error">
					<div>
						<div style={{ textAlign: "right" }}>
							<svg
								width="30px"
								height="30px"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								style={{
									cursor: "pointer",
								}}
								onClick={() => {
									setDelIntegrationsConf(false)
								}}
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
									fill="#0F1729"
								/>
							</svg>
						</div>
						<h3>Вы уверены, что хотите удалить проект ? </h3>
						<div className="btn-wrapper">
							<button
								onClick={() => {
									DelIntegrations(selectArray)
								}}
							>
								Да
							</button>
							<button
								onClick={() => {
									setDelIntegrationsConf(false)
								}}
							>
								Отменить
							</button>
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</>
	)
}
