import { useContext } from "react"
import { GlobalContext } from "../../context/GlobalContext"

export const Header = (props: any) => {
	const auth = useContext(GlobalContext)

	return (
		<header>
			<div className="row">
				<div className="col-md-4">
					<h1>
						<span>
							<svg
								width="30px"
								height="30px"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M4 12H20M4 8H20M4 16H12"
									stroke="#000000"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
					</h1>
				</div>
				<div className="col-md-8 exit-wrapper">
					<button
						onClick={() => {
							auth.user.Logout()
							window.location.replace("/")
						}}
					>
						Выход
					</button>
				</div>
			</div>
		</header>
	)
}
