import { Link, useParams } from "react-router-dom"
import axios from "axios"
import xlsx from "json-as-xlsx"
import { ChangeEvent, useContext, useEffect, useState } from "react"
import { GlobalContext } from "../../../context/GlobalContext"
import { IntegrationsItem } from "../../../classes"
import { Loader } from "../../../component/Loader"
import { FormatSelector } from "../../../component/FormatSelector"
import { Pagination } from "../../../component/Pagination"

type FormFields = {
	title: string
	productTitle: string
	miniDesc: string
	desc: string
	productLink: string
	productImage: string
	tz: string
	soc: string
	price: number
	maxIntegrations: string
	format1: boolean
	format2: boolean
	format3: boolean
	format4: boolean
	format5: boolean
}

export const IntegrationItemPage = () => {
	const auth = useContext(GlobalContext)
	const { id } = useParams()
	const [integration] = useState(new IntegrationsItem())
	const [load, setLoad] = useState(true)

	const [loadForm2, setLoadForm2] = useState(true)
	const [loadForm3, setLoadForm3] = useState(true)
	const [showFormUpdate, setShowFormUpdate] = useState(false)
	const [formUpdate, setFormUpdate] = useState<FormFields>({
		title: "",
		productTitle: "",
		miniDesc: "",
		desc: "",
		productLink: "",
		productImage: "",
		tz: "",
		soc: "",
		price: 0,
		maxIntegrations: "",
		format1: false,
		format2: false,
		format3: false,
		format4: false,
		format5: false,
	})
	const [fileNameUpdate, setFileNameUpdate] = useState("")
	const [showFormat, setShowFormat] = useState(false)
	const [delIntegrationsConf, setDelIntegrationsConf] = useState(false)
	const [loadApplic, setLoadApplic] = useState(true)

	const DelIntegrations = (array: number[]) => {
		integration
			.DelItem(array, auth.user.userToken)
			.then(function (response) {
				alert(`Интеграция успешно удалена`)
				window.location.replace("/integration/")
			})
			.catch(function (error) {
				alert("error")
			})
	}

	const handleSubmitUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		if (loadForm3) {
			setLoadForm3(false)

			const formData = new FormData()

			formData.append("title", formUpdate.title)
			formData.append("productTitle", formUpdate.productTitle)
			formData.append("miniDesc", formUpdate.miniDesc)
			formData.append("desc", formUpdate.desc)
			formData.append("productLink", formUpdate.productLink)
			formData.append("productImage", formUpdate.productImage)
			formData.append("tz", formUpdate.tz)
			formData.append("price", `${formUpdate.price}`)
			formData.append("maxIntegrations", formUpdate.maxIntegrations)
			formData.append("format1", `${formUpdate.format1}`)
			formData.append("format2", `${formUpdate.format2}`)
			formData.append("format3", `${formUpdate.format3}`)
			formData.append("format4", `${formUpdate.format4}`)
			formData.append("format5", `${formUpdate.format5}`)

			await axios
				.post(`https://api.blogerito.ru/dashboard/integrations-update/`, formData, {
					params: {
						id: integration.pk,
					},
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: "Token " + auth.user.userToken,
					},
				})
				.then((res) => {
					setLoadForm3(true)
					console.log(res.data)
					alert(res.data.msg)
				})
				.catch((error) => {
					setLoadForm3(true)
					console.log(error)
					alert("error")
				})
		}
	}

	const changeHandlerUpdate = (event: ChangeEvent<HTMLInputElement>) => {
		setFormUpdate({ ...formUpdate, [event.target.name]: event.target.value })
	}

	const handlerImageUpdate = (e: any) => {
		setFormUpdate({ ...formUpdate, productImage: e.target.files[0] })
		setFileNameUpdate(e.target.files[0].name)
	}

	const ToExcel = () => {
		integration
			.LoadApplications(auth.user.userToken, 1, true)
			.then(function (response) {
				const data = [
					{
						sheet: "Adults",
						columns: [
							{ label: "id", value: "id" },
							{ label: "Дата создания", value: "date" },
							{ label: "Статус заявки", value: "status" },
							{ label: "Причина отмены", value: "cancellationReason" },
							{ label: "Дата изменения статуса", value: "statusDate" },
							{ label: "id проекта", value: "integration_id" },
							{ label: "Название проекта", value: "integration_title" },
						],
						content: response,
					},
				]
				let settings = {
					fileName: "Заявки блогера",
				}
				xlsx(data, settings)
			})
			.catch(function (error) {
				alert("error")
				setLoadApplic(false)
			})
	}

	const LoadApplications = (page: number = 1) => {
		setLoadApplic(true)
		integration
			.LoadApplications(auth.user.userToken, page)
			.then(function (response) {
				setLoadApplic(false)
			})
			.catch(function (error) {
				alert("error")
				setLoadApplic(false)
			})
	}

	useEffect(() => {
		integration.pk = Number(id)
		integration
			.LoadData(auth.user.userToken)
			.then(function (response) {
				setLoad(false)
			})
			.catch(function (error) {
				alert("error")
			})

		LoadApplications()
	}, [id])

	if (load) return <Loader />

	return (
		<>
			<div className="content">
				<div className="row">
					<div className="col-md-6">
						<div className="block-wrapper bread">
							<Link to={"/integration/"}>Проекты</Link>
							{">"}
							<span>Проект детально</span>
						</div>
					</div>
					<div className="col-md-6">
						<div className="right">
							<button
								className="btn"
								style={{ marginTop: 0, background: "#9b51e0" }}
								onClick={() => {
									setShowFormUpdate(true)
									setLoadForm2(true)

									integration
										.LoadStat(auth.user.userToken)
										.then(function (response: any) {
											setFormUpdate({ ...response.msg })
											setLoadForm2(false)
										})
										.catch(function (error: any) {
											alert("error")
										})
								}}
							>
								Редактировать
							</button>
							<button
								className="btn"
								style={{ marginTop: 0, background: "#ff4646" }}
								onClick={() => {
									setDelIntegrationsConf(true)
								}}
							>
								Удалить
							</button>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="block-wrapper desc-wrapper">
							<div className="product-name">
								<div className="img">
									<div style={{ backgroundImage: `url(${integration.image})` }}></div>
								</div>
								<div className="desc">
									<div className="grid">
										<div>
											<h3>{integration.title}</h3>
											<p>
												<span>Формат рекламы: </span> {integration.format1 ? "Рекламный пост, " : ""}
												{integration.format2 ? "Выкуп на маркетплейсе, " : ""}
												{integration.format3 ? "Отзыв на маркетплейсе, " : ""}
												{integration.format4 ? "Раздача товаров подписчикам, " : ""}
												{integration.format5 ? "Видеоотзыв на маркетплейсе" : ""}
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="block-wrapper desc-wrapper">
							<p>
								<span>Селлер:</span>{" "}
								<Link to={`/sellers/${integration.seller.pk}/`}>{integration.seller.user.email}</Link>
							</p>
							<p>
								<span>Количество заявок:</span> {integration.applicationCount}
							</p>
							<p>
								<span>Количество доступных интеграций:</span> {integration.maxIntegrations}
							</p>
							<p>
								<span>Название товара:</span> {integration.productTitle}
							</p>
							<p>
								<span>Ссылка на товар или ТЗ:</span>{" "}
								<Link target="_blank" to={`${integration.tz}`}>
									ТЗ
								</Link>
							</p>
						</div>
					</div>
					<div className="col-md-6">
						<div className="block-wrapper desc-wrapper">
							<h4>Краткое описание:</h4>
							<p>{integration.miniDesc}</p>
							<div className="project-desc">
								<h4>Описание:</h4>
								<p>{integration.desc}</p>
							</div>
						</div>
					</div>

					<div className="col-12">
						<div className="right">
							{auth.user.userRole === "director" ? (
								<button
									className="btn"
									style={{ marginBottom: 20, background: "#207444" }}
									onClick={() => {
										ToExcel()
									}}
								>
									Выгрузить в excel
								</button>
							) : (
								""
							)}
						</div>
					</div>

					<div className="col-12">
						<div className="block-wrapper table-wrapper">
							<h3>Заявки в проект</h3>
							{loadApplic ? (
								<p
									style={{
										fontSize: 22,
										marginBottom: 25,
										marginTop: 30,
										fontWeight: "bold",
									}}
								>
									Загрузка...
								</p>
							) : (
								<table className="list-table">
									<thead>
										<tr>
											<th>id</th>
											<th>Блогер</th>
											<th>Статус</th>
											<th>Дата создания</th>
											<th>Дата изменения статуса</th>
										</tr>
									</thead>
									<tbody>
										{integration.list
											? integration.list.map((item: any, index: number) => {
													return (
														<tr>
															<td>
																<div>
																	<div>{item.id}</div>
																</div>
															</td>
															<td>
																<div>
																	<Link to={`/blogger/${item.bloger.pk}/`} target="_blank">
																		{item.bloger.user.email}
																	</Link>
																</div>
															</td>
															<td>
																<div
																	style={{
																		color:
																			item.status === "Отменено рекламодателем" ||
																			item.status === "Отменено блогером"
																				? "red"
																				: item.status !==
																						"Ожидает подтверждения от блогера" &&
																				  item.status !==
																						"Ожидает подтверждения от рекламодателя"
																				? "green"
																				: "",
																	}}
																>
																	{item.status}
																	{item.cancellationReason ? (
																		<span style={{ color: "black" }}>
																			<br />
																			Причина: {item.cancellationReason}
																		</span>
																	) : (
																		""
																	)}
																</div>
															</td>
															<td>
																<div>{item.date}</div>
															</td>
															<td>
																<div>{item.statusDate}</div>
															</td>
														</tr>
													)
											  })
											: ""}
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>

				{!loadApplic ? (
					<Pagination
						page={integration.targetPage}
						pageCount={integration.countPage}
						loadData={LoadApplications}
					/>
				) : (
					""
				)}
			</div>

			{showFormUpdate ? (
				<div className="popup-integration">
					<div className="">
						<div style={{ textAlign: "right" }}>
							<svg
								width="30px"
								height="30px"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								style={{
									cursor: "pointer",
								}}
								onClick={() => {
									setShowFormUpdate(false)
								}}
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
									fill="#0F1729"
								/>
							</svg>
						</div>
						{loadForm2 ? (
							<div style={{ backgroundColor: "white", borderRadius: 10, paddingTop: 20 }}>
								<Loader />
							</div>
						) : (
							<form onSubmit={handleSubmitUpdate}>
								<label>
									Название интеграции
									<input
										type="text"
										maxLength={255}
										required
										style={{ backgroundColor: "white !important", color: "black !important" }}
										name="title"
										value={formUpdate.title}
										onChange={changeHandlerUpdate}
									/>
								</label>
								<label>
									Название товара
									<input
										type="text"
										maxLength={255}
										required
										name="productTitle"
										value={formUpdate.productTitle}
										onChange={changeHandlerUpdate}
									/>
								</label>
								<label>
									Количество возможных интеграций в рамках этого задания
									<input
										required
										type="number"
										min={formUpdate.maxIntegrations}
										step={1}
										value={formUpdate.maxIntegrations}
										name="maxIntegrations"
										onChange={changeHandlerUpdate}
									/>
								</label>
								<label style={{ display: "none" }}>
									Социальная сеть
									<select
										required
										onChange={(e) => {
											setFormUpdate({ ...formUpdate, soc: e.target.value })
										}}
									>
										<option value="" disabled>
											Выбрать
										</option>
										<option value={"instagram"} selected>
											Instagram
										</option>
										<option value={"youtube"}>Youtube</option>
										<option value={"vk"}>Vk</option>
										<option value={"rutube"}>Rutube</option>
										<option value={"telegram"}>Telegram</option>
										<option value={"any"}>Любая</option>
									</select>
								</label>

								<label>
									Формат рекламы
									<FormatSelector
										form={formUpdate}
										setForm={setFormUpdate}
										showFormat={showFormat}
										setShowFormat={setShowFormat}
									/>
								</label>

								<label>
									Короткое описание
									<input
										type="text"
										maxLength={510}
										required
										name="miniDesc"
										value={formUpdate.miniDesc}
										onChange={changeHandlerUpdate}
									/>
								</label>

								<label>
									Цена товара (руб.)
									<input
										type="number"
										min={0}
										step={1}
										required
										name="price"
										value={formUpdate.price}
										onChange={changeHandlerUpdate}
									/>
								</label>

								<label>
									Описание и ваши пожелания
									<textarea
										required
										name="msg"
										value={formUpdate.desc}
										onChange={(e) => setFormUpdate({ ...formUpdate, desc: e.target.value })}
									/>
								</label>

								<label>
									Техническое задание на покупку товара{" "}
									<b style={{ fontSize: 12, fontWeight: 300 }}>
										(будет автоматически выслано блогеру после приема заявки)
									</b>
									<input
										type="text"
										maxLength={510}
										required
										name="tz"
										value={formUpdate.tz}
										onChange={changeHandlerUpdate}
									/>
								</label>

								<label>
									Ссылка на рекламируемую страницу{" "}
									<b style={{ fontSize: 12, fontWeight: 300 }}>(можно оставить пустым)</b>
									<input
										type="text"
										maxLength={510}
										name="productLink"
										value={formUpdate.productLink}
										onChange={changeHandlerUpdate}
									/>
								</label>

								<label style={{ cursor: "pointer" }}>
									<div className="dop-file project">
										Прикрепите фото товара для его изменения
										<input
											type="file"
											name="productImage"
											onChange={handlerImageUpdate}
											accept="image/*"
										/>
									</div>
								</label>

								<button>Обновить интеграцию</button>
							</form>
						)}
					</div>
				</div>
			) : (
				""
			)}

			{delIntegrationsConf ? (
				<div className="popup-error">
					<div>
						<div style={{ textAlign: "right" }}>
							<svg
								width="30px"
								height="30px"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								style={{
									cursor: "pointer",
								}}
								onClick={() => {
									setDelIntegrationsConf(false)
								}}
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
									fill="#0F1729"
								/>
							</svg>
						</div>
						<h3>Вы уверены, что хотите удалить интеграции ? </h3>
						<div className="btn-wrapper">
							<button
								onClick={() => {
									DelIntegrations([])
								}}
							>
								Да
							</button>
							<button
								onClick={() => {
									setDelIntegrationsConf(false)
								}}
							>
								Отменить
							</button>
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</>
	)
}
